import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullMaterialModule } from 'src/utils/full-material.module';
import { HomeComponent } from './home/home.component';
import { ResearchComponent } from './research/research.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterModule } from './layout/footer/footer.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// import { HomeModule } from './home/home.module';

// Note we need a separate function as it's required
// by the AOT compiler
export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FooterModule,
    FullMaterialModule,
    LottieModule.forRoot({ player: playerFactory }),
    // HomeModule,
  ],
  providers: [CdkVirtualScrollViewport],
  bootstrap: [AppComponent],
})
export class AppModule {}
