<!--The content below is only a placeholder and can be replaced.-->
<div
  style="display: flex; flex-direction: column; justify-content: space-between;"
>
  <div fxFlex="100%">
    <div [ngClass]="{ headerWrapper: router.url !== '/' }">
      <app-header></app-header>
    </div>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
