<main [@routeAnimation]>
  <section id="missionSection" style="background: #f5f5f5; color: #424242">
    <div
      style="
        padding: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
      "
      fxLayout.lt-md="row wrap"
      fxLayout.gt-sm="row-reverse wrap"
    >
      <div
        fxFlex.lt-md="100%"
        fxFlex.gt-sm="50%"
        style="
          float: right;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <h1
          fxHide.gt-sm
          class="landingHeader"
          style="margin-bottom: -5px; text-align: center"
        >
          Our mission
        </h1>
        <div
          class="networkBrainLotti"
          ngClass.gt-sm="networkDesktop"
          ngClass.sm="networkMedium"
          ngClass.lt-sm="networkMobile"
        >
          <ng-lottie
            [options]="getLottieAnimation('GreenBrain')"
            (animationCreated)="animationCreated($event)"
          ></ng-lottie>
        </div>
      </div>
      <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
        <h1 fxHide.lt-md class="landingHeader">OUR MISSION</h1>

        <p
          class="body"
          ngClass.xs="mobileBodyText"
          ngClass.gt-xs="desktopBodyText"
        >
          The Hsieh lab wants to understand the mechanisms underlying neural
          development and adult neurogenesis. Working primarily in mouse models
          and in vitro systems such as patient-derived induced pluripotent stem
          cells, the Hsieh lab studies the function of genes involved in
          epilepsy disorders. We study mesial temporal lobe epilepsy, the most
          common intractable epilepsy in adults, and genetic epilepsies, a group
          of rare neurodevelopmental disorders characterized by early onset
          seizures. We also collaborate with labs to study the role of genes
          that contribute to hyperexcitability in early- and late-onset
          degenerative diseases like Alzheimer’s disease. The goal of the Hsieh
          lab is to find ways to target abnormal functions of genes in
          developmental and degenerative conditions.
        </p>
      </div>
    </div>
  </section>
  <section id="overviewSection">
    <div
      style="margin-left: auto; margin-right: auto; padding: 15px; width: 90%"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-around center"
        style="align-items: center"
      >
        <h1
          fxHide.gt-sm
          class="landingHeaderAlt"
          style="text-align: center; margin-bottom: -25px; width: 100%"
        >
          Overview of our research
        </h1>
        <div class="brainFitLotti">
          <ng-lottie
            [options]="getLottieAnimation('BrainFit')"
            (animationCreated)="animationCreated($event)"
          ></ng-lottie>
        </div>
        <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
          <h1 fxHide.lt-md class="landingHeaderAlt">
            Overview of our research
          </h1>
          <p
            class="bodyAlt"
            ngClass.lt-md="bodyAltFirst"
            ngClass.xs="mobileBodyText"
            ngClass.gt-xs="desktopBodyText"
            style="margin-bottom: 10px"
          >
            The Hsieh laboratory focuses on 4 major areas:
          </p>
          <ul ngClass.xs="mobileBodyText" class="bodyAlt">
            <li>(1) iPSC Models & Genetic Epilepsies</li>
            <li>(2) Adult Hippocampal Neurogenesis & Epilepsy</li>
            <li>(3) Human 3D Brain Organoid Models</li>
            <li>(4) UTSA Stem Cell Core and Patient Recruitment</li>
            <button
              class="publicationsButton"
              style="margin-top: 16px"
              (click)="clickRoute('research')"
              routerLinkActive="router-link-active"
              mat-raised-button
            >
              Learn More
            </button>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section id="aboutSection">
    <mat-card id="overviewCard">
      <mat-card-header fxLayout="row wrap">
        <img
          mat-card-avatar
          src="https://hsiehlab.imgix.net/hsiehFull.jpg?auto=format&fit=facearea&w=400&h=400&facepad=3"
          alt="Photo of Jenny Hsieh, Principal Investigator"
          class="jennyAvatar"
        />
        <mat-card-title>Jenny Hsieh, Ph.D.</mat-card-title>
        <mat-card-subtitle
          >Professor, Department of Neuroscience, Developmental and Regenerative
          Biology</mat-card-subtitle
        >
        <mat-card-subtitle>
          Semmes Foundation Distinguished Chair in Cell Biology
        </mat-card-subtitle>
        <mat-card-subtitle>
          Director, UTSA Brain Health Consortium
        </mat-card-subtitle>
        <mat-card-subtitle>
          The University of Texas at San Antonio
        </mat-card-subtitle>
        <mat-card-subtitle class="buttonContainer">
          <button
            class="publicationsButton"
            style="margin-top: 16px"
            (click)="clickRoute('publications')"
            routerLinkActive="router-link-active"
            mat-raised-button
          >
            View Publications
          </button>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </section>
</main>
