<div class="footerContainer" fxLayout.gt-xs="row" fxLayout.xs="column">
  <div
    class="footerSections"
    fxFlex.gt-md="25%"
    fxFlex.lt-lg="50%"
    fxFlex.xs="100%"
  >
    <div style="max-width: 400px">
      <div class="alignCenter footerHeaders">
        <h1 class="header" style="margin: 0px 5px 0px 0px">HSIEH LAB</h1>
        <i class="fas fa-brain"></i>
      </div>
      <p style="font-size: 12px; line-height: 1.5">
        The Hsieh lab is a neural stem cell biology laboratory in the Department
        of Neuroscience, Developmental and Regenerative Biology at The
        University of Texas at San Antonio. We are affiliated with the UTSA
        Brain Health Consortium. Our research program strives to decipher the
        gene regulatory mechanisms that control neural stem cell fate. We apply
        these mechanisms to understand the causes of epilepsy and develop better
        therapeutics.
      </p>
    </div>
  </div>
  <div
    class="footerSections"
    fxFlex.gt-md="25%"
    fxFlex.lt-lg="50%"
    fxFlex.xs="100%"
  >
    <div>
      <h1 class="footerHeaders">CONTACT</h1>
      <p class="alignCenter">
        <i class="material-icons footerIcons">email</i>
        <a href="mailto:jenny.hsieh@utsa.edu" class="footerLink"
          >jenny.hsieh@utsa.edu</a
        >
      </p>
      <p>For BHC matters:</p>
      <p class="alignCenter">
        <i class="material-icons footerIcons">email</i>
        <a href="mailto:gregory.granados@utsa.edu" class="footerLink"
          >gregory.granados@utsa.edu</a
        >
      </p>
      <p>For Hsieh Lab matters:</p>
      <p class="alignCenter">
        <i class="material-icons footerIcons">email</i>
        <a href="mailto:sergio.gameroalameda@utsa.edu" class="footerLink"
          >sergio.gameroalameda@utsa.edu</a
        >
      </p>
    </div>
  </div>
  <div
    class="footerSections"
    fxFlex.gt-md="25%"
    fxFlex.lt-lg="50%"
    fxFlex.xs="100%"
  >
    <div>
      <h1 class="footerHeaders">LOCATION</h1>
      <p class="alignCenter">
        <i class="material-icons footerIcons">room</i>SEB 4.166
      </p>
      <p>The University of Texas at San Antonio</p>
      <p>One UTSA Circle</p>
      <p>San Antonio, TX 78249</p>
    </div>
  </div>
  <div
    class="footerSections"
    fxFlex.gt-md="25%"
    fxFlex.lt-lg="50%"
    fxFlex.xs="100%"
  >
    <div>
      <h1 class="footerHeaders">RESOURCES</h1>
      <p class="alignCenter">
        <i class="material-icons footerIcons">exit_to_app</i>
        <a href="https://www.utsa.edu/bhc/" class="footerLink"
          >Brain Health Consortium</a
        >
      </p>
      <p class="alignCenter">
        <i class="material-icons footerIcons">exit_to_app</i>
        <a
          href="https://www.utsa.edu/sciences/neuroscience-developmental-regenerative-biology/"
          class="footerLink"
          >UTSA Department of Neuroscience, Developmental and Regenerative
          Biology</a
        >
      </p>
      <p class="alignCenter">
        <i class="material-icons footerIcons">exit_to_app</i>
        <a
          href="https://www.utsa.edu/bhc/core/stem-cell-core"
          class="footerLink"
          >UTSA Stem Cell Core</a
        >
      </p>
      <div>
        <p>Check us out on Twitter!</p>
        <a
          class="twitter-follow-button"
          href="https://twitter.com/jennyhsieh5"
          data-show-screen-name="false"
          data-show-count="false"
          data-size="large"
        >
          <i class="fab fa-twitter"></i>
          <span class="label">Follow @jennyhsieh5</span>
        </a>
      </div>
    </div>
  </div>
</div>
