import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { firestore } from 'firebase';

// import * as firebase from 'firebase';
// webkit client id `865395906913-7dlmc7bjhfokhqnvluq43out06g4vmq3.apps.googleusercontent.com`
// webkit client secret `m8aIIfu4kBjQJnG1_niQEJzs`

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Hsieh-Lab';
  public db: firestore.Firestore;

  constructor(public router: Router) {
    // Initialize Cloud Firestore through Firebase
    // initializeApp({
    //   apiKey: 'AIzaSyBkp4GPF3irp4w8n3ptywP5k6-f89U8XO0',
    //   authDomain: 'bubbly-sandbox-260809.firebaseapp.com',
    //   databaseURL: 'https://bubbly-sandbox-260809.firebaseio.com',
    //   storageBucket: 'bubbly-sandbox-260809.appspot.com',
    //   projectId: 'bubbly-sandbox-260809'
    // });
    // this.db = firestore();
    // const hsiehRef = this.db.collection('hsieh');
    // console.log(hsiehRef);
    // hsiehRef.get().then(value => {
    //   console.log(value);
    // });
    // const test = hsiehRef
    //   .where('hello', '==', 'World!')
    //   .get()
    //   .then(x => console.log(x));
    // console.log(test);
    // this.db
    //   .collection('test')
    //   .add({
    //     first: 'Ada',
    //     last: 'Lovelace',
    //     born: 1815
    //   })
    //   .then(function(docRef) {
    //     console.log('Document written with ID: ', docRef.id);
    //   })
    //   .catch(function(error) {
    //     console.error('Error adding document: ', error);
    //   });
  }
}
