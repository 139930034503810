import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { routeAnimation } from '../shared/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [routeAnimation],
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  options: AnimationOptions;

  constructor(public router: Router) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  getLottieAnimation(option: string) {
    switch (option) {
      case 'BrainFit':
        this.options = {
          path: '/assets/lottie/brainFit.json',
        };
        break;
      case 'Brain':
        this.options = {
          path: '/assets/lottie/6741-brain.json',
        };
        break;
      case 'GreenBrain':
        this.options = {
          path: '/assets/lottie/greenBrain.json',
        };
        break;
      case 'Lab':
        this.options = {
          path: '/assets/lottie/11138-laboratory.json',
        };
        break;
      default:
        break;
    }
    return this.options;
  }

  clickRoute(route: string) {
    this.router.navigateByUrl(route);
  }
}
