import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullMaterialModule } from 'src/utils/full-material.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, FlexLayoutModule, FullMaterialModule],
  exports: [FooterComponent],
})
export class FooterModule {}
