import {
  Component,
  OnInit,
  Input,
  OnChanges,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { routeAnimation } from 'src/app/shared/animations';

export interface Tab {
  name: string;
  route: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [routeAnimation],
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public changeBG = false;
  public tabs: Tab[] = [
    {
      name: 'Home',
      route: '',
    },
    {
      name: 'Research',
      route: 'research',
    },
    {
      name: 'Lab Members',
      route: 'members',
    },
    {
      name: 'Publications',
      route: 'publications',
    },
    {
      name: 'News',
      route: 'news',
    },
    {
      name: 'Fun',
      route: 'fun',
    },
    {
      name: 'Contact',
      route: 'contact',
    },
  ];
  constructor(public router: Router) {}

  ngOnInit() {}

  clickRoute(route: string) {
    this.router.navigateByUrl(route);
  }

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (window.pageYOffset > 10) {
      this.changeBG = true;
    } else {
      this.changeBG = false;
    }
  }
}
