<ng-container *ngIf="router.url === '/'">
  <div
    ngClass.xs="mobileHeight"
    ngClass.gt-xs="desktopHeight"
    class="landingHeader"
    [@routeAnimation]
  >
    <mat-toolbar [ngClass]="{ fade: this.changeBG }" class="homeToolbar">
      <div class="spaceBetween fullWidth">
        <div (click)="clickRoute('')" class="logoWrapper alignCenter">
          <h1 class="header">
            HSIEH LAB
          </h1>
          <i class="fas fa-brain"></i>
        </div>
        <div class="desktopMenu">
          <button
            *ngFor="let tab of this.tabs"
            mat-button
            (click)="clickRoute(tab.route)"
            routerLinkActive="router-link-active"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="mobileMenu">
          <button mat-button [matMenuTriggerFor]="menu">
            <i class="material-icons">menu</i>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngFor="let tab of this.tabs"
              mat-menu-item
              (click)="clickRoute(tab.route)"
              routerLinkActive="router-link-active"
            >
              {{ tab.name }}
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
    <div
      style="
        text-align: center;
        width: 100%;
        color: white;
        position: absolute;
        top: 25%;
      "
    >
      <mat-card ngClass.xs="mobileCard" ngClass.gt-xs="desktopCard">
        <h1
          style="margin: 0.67em auto;"
          ngClass.xs="mobileHeaderText"
          ngClass.gt-xs="headerText"
        >
          HSIEH LAB
        </h1>
        <h3>
          Neurodevelopment and Adult Neurogenesis in Epilepsy
        </h3>
      </mat-card>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="router.url !== '/'">
  <mat-toolbar class="generalToolbar">
    <div class="spaceBetween fullWidth">
      <div (click)="clickRoute('')" class="logoWrapper alignCenter">
        <h1 class="header">
          HSIEH LAB
        </h1>
        <i class="fas fa-brain"></i>
      </div>
      <div class="desktopMenu">
        <button
          *ngFor="let tab of this.tabs"
          mat-button
          (click)="clickRoute(tab.route)"
          routerLinkActive="router-link-active"
        >
          {{ tab.name }}
        </button>
      </div>
      <div class="mobileMenu">
        <button mat-button [matMenuTriggerFor]="menu">
          <i class="material-icons">menu</i>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngFor="let tab of this.tabs"
            mat-menu-item
            (click)="clickRoute(tab.route)"
            routerLinkActive="router-link-active"
          >
            {{ tab.name }}
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</ng-container>
