import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ResearchComponent } from './research/research.component';
import { PublicationsComponent } from './publications/publications.component';
import { NewsComponent } from './news/news.component';
import { MembersComponent } from './members/members.component';
import { FunComponent } from './fun/fun.component';

import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((mod) => mod.ContactModule),
  },
  {
    path: 'fun',
    loadChildren: () => import('./fun/fun.module').then((mod) => mod.FunModule),
  },
  {
    path: 'members',
    loadChildren: () =>
      import('./members/members.module').then((mod) => mod.MembersModule),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./news/news.module').then((mod) => mod.NewsModule),
  },
  {
    path: 'publications',
    loadChildren: () =>
      import('./publications/publications.module').then(
        (mod) => mod.PublicationsModule
      ),
  },
  {
    path: 'research',
    loadChildren: () =>
      import('./research/research.module').then((mod) => mod.ResearchModule),
  },
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
